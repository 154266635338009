import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
};

/** Input for assigning unassign owners to a task */
export type AssignUnassignTaskOwnersInput = {
  taskId: Scalars['Int'];
  userIds: Array<Scalars['Int']>;
};

export type Attachment = {
  __typename?: 'Attachment';
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  task: Task;
  taskId: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
};

/** CognitoGroups for Users */
export enum CognitoGroups {
  HavenlyAdmin = 'HavenlyAdmin',
  HavenlyUser = 'HavenlyUser',
  TenantAdmin = 'TenantAdmin',
  TenantUser = 'TenantUser'
}

export type Comment = {
  __typename?: 'Comment';
  content: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  task: Task;
  taskId: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  user: User;
  userId: Scalars['Int'];
};

/** Input for creating or updating comments */
export type CreateCommentInput = {
  content: Scalars['String'];
  taskId: Scalars['Int'];
  usersIDToNotify?: InputMaybe<Array<Scalars['Int']>>;
};

/** Input for creating or updating tasks */
export type CreateTaskInput = {
  attachments?: InputMaybe<Array<Scalars['String']>>;
  content: Scalars['String'];
  dueDate: Scalars['DateTime'];
  payload: Scalars['JSON'];
  status: TaskStatuses;
  taskTypeId: Scalars['Int'];
  tenantId?: InputMaybe<Array<Scalars['Int']>>;
  title: Scalars['String'];
};

/** Input for filtering through payload fields */
export type FilterPayloadFieldInput = {
  filterStatus: PayloadFilterStatuses;
  payloadField: FilterablePayloadFields;
};

/** Available payload fields for filtering */
export enum FilterablePayloadFields {
  SimilarPropUrl = 'similarPropUrl'
}

/** allowed extensions for thumbnail images */
export enum Material_Thumbnail_Extensions {
  Png = 'PNG'
}

/** Input for mass assigning due date to a tasks */
export type MassAssignDueDateToTasksInput = {
  dueDate: Scalars['DateTime'];
  taskIds: Array<Scalars['Int']>;
};

/** Input for mass assigning owners to a tasks */
export type MassAssignOwnersToTasksInput = {
  taskIds: Array<Scalars['Int']>;
  userIds: Array<Scalars['Int']>;
};

/** Input for mass assigning status to a tasks */
export type MassAssignStatusToTasksInput = {
  status: TaskStatuses;
  taskIds: Array<Scalars['Int']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Creates an additional room shell task. */
  additionalRoomShellTask: Task;
  /** Assign owners to a task */
  assignOwnersToTask: Task;
  /** Start task (marks as InProgress) */
  beginTask: Task;
  /** Mark a task as blocked */
  blockTask: Task;
  /** Cancels a task. Prevents task being sent to vendor, if possible */
  cancelTask: Task;
  /** Create comment */
  createComment: Comment;
  /** Generate a material source asset upload URL. */
  createMaterialSourceAssetUploadURL: Scalars['String'];
  /** Generate a material thumbnail upload URL. */
  createMaterialThumbnailUploadURL: Scalars['String'];
  /**
   * Generate a material source asset upload URL.
   * @deprecated use createPropGenericSourceAssetUploadURL
   */
  createPropBatchSourceAssetUploadURL: Scalars['String'];
  /** Generate a material source asset upload URL. */
  createPropGenericSourceAssetUploadURL: Scalars['String'];
  /** Generate a room shell source asset upload URL. */
  createRoomShellSourceAssetUploadURL: Scalars['String'];
  /** Create subtask */
  createSubtask?: Maybe<Task>;
  /** Create task */
  createTask: Task;
  /** Creates a user account. User will need to set their own password on first login. */
  createUser: User;
  /** Mass assign due date to tasks */
  massAssignDueDateToTasks: Array<Task>;
  /** Mass assign owners to tasks */
  massAssignOwnersToTasks: Array<Task>;
  /** Mass assign status to tasks */
  massAssignStatusToTasks: Array<Task>;
  /** Request a Material Revision */
  reviseTask: Task;
  /** Sends Revision Task to Enhancement */
  revisionApproveEnhancementTask: Task;
  /** Approves Task and sends Revision straight to vendor. */
  revisionApproveTask: Task;
  /** Set groups for user */
  setGroupsForUser: User;
  /** Triggers SQS message to update room shell task with most recent room images. */
  triggerRoomShellRoomImagesUpdate: SimpleResponse;
  /** Unassign owners from a task */
  unassignOwnersFromTask: Task;
  /** Unblocks a task */
  unblockTask: Task;
  /** Update task */
  updateTask: Task;
};


export type MutationAdditionalRoomShellTaskArgs = {
  taskId: Scalars['ID'];
};


export type MutationAssignOwnersToTaskArgs = {
  data: AssignUnassignTaskOwnersInput;
};


export type MutationBeginTaskArgs = {
  taskId: Scalars['ID'];
};


export type MutationBlockTaskArgs = {
  comment: Scalars['String'];
  taskId: Scalars['ID'];
};


export type MutationCancelTaskArgs = {
  taskId: Scalars['ID'];
};


export type MutationCreateCommentArgs = {
  data: CreateCommentInput;
};


export type MutationCreateMaterialSourceAssetUploadUrlArgs = {
  materialTaskId: Scalars['ID'];
};


export type MutationCreateMaterialThumbnailUploadUrlArgs = {
  extension: Material_Thumbnail_Extensions;
  materialTaskId: Scalars['ID'];
};


export type MutationCreatePropBatchSourceAssetUploadUrlArgs = {
  baseAssetHash: Scalars['String'];
  propBatchTaskId: Scalars['ID'];
};


export type MutationCreatePropGenericSourceAssetUploadUrlArgs = {
  baseAssetHash: Scalars['String'];
  propGenericTaskId: Scalars['ID'];
};


export type MutationCreateRoomShellSourceAssetUploadUrlArgs = {
  baseAssetHash: Scalars['String'];
  roomId: Scalars['ID'];
  roomShellRequestId: Scalars['ID'];
};


export type MutationCreateSubtaskArgs = {
  data: SubtaskInput;
};


export type MutationCreateTaskArgs = {
  data: CreateTaskInput;
};


export type MutationCreateUserArgs = {
  email: Scalars['String'];
  group: CognitoGroups;
  name: Scalars['String'];
  tenantId?: InputMaybe<Scalars['ID']>;
};


export type MutationMassAssignDueDateToTasksArgs = {
  data: MassAssignDueDateToTasksInput;
};


export type MutationMassAssignOwnersToTasksArgs = {
  data: MassAssignOwnersToTasksInput;
};


export type MutationMassAssignStatusToTasksArgs = {
  data: MassAssignStatusToTasksInput;
};


export type MutationReviseTaskArgs = {
  comment: Scalars['String'];
  taskId: Scalars['ID'];
};


export type MutationRevisionApproveEnhancementTaskArgs = {
  taskId: Scalars['ID'];
};


export type MutationRevisionApproveTaskArgs = {
  taskId: Scalars['ID'];
};


export type MutationSetGroupsForUserArgs = {
  data: SetGroupsForUserInput;
};


export type MutationTriggerRoomShellRoomImagesUpdateArgs = {
  roomShellRequestId: Scalars['Int'];
};


export type MutationUnassignOwnersFromTaskArgs = {
  data: AssignUnassignTaskOwnersInput;
};


export type MutationUnblockTaskArgs = {
  comment: Scalars['String'];
  taskId: Scalars['ID'];
};


export type MutationUpdateTaskArgs = {
  data: UpdateTaskInput;
};

/** Statuses for filtering through payload fields */
export enum PayloadFilterStatuses {
  Empty = 'Empty',
  NotEmpty = 'NotEmpty'
}

export type Query = {
  __typename?: 'Query';
  commentsByTaskId?: Maybe<Array<Comment>>;
  /** Get authenticated user. */
  me: User;
  /** Returns a presigned url (valid for several minutes) to download the associated 3DSMax Archive. */
  roomShellTaskSourceAsset: Scalars['String'];
  /** Search for users. */
  searchUsers: Array<User>;
  /** Get task by id */
  task: Task;
  /** Get taskTypes */
  taskTypes?: Maybe<Array<TaskType>>;
  /** Get tasks paginated */
  tasksPaginated: TaskPagination;
  /** Get users paginated */
  usersPaginated: UserPagination;
};


export type QueryCommentsByTaskIdArgs = {
  taskId: Scalars['Int'];
};


export type QueryRoomShellTaskSourceAssetArgs = {
  taskId: Scalars['ID'];
};


export type QuerySearchUsersArgs = {
  excludeUserIds?: InputMaybe<Array<Scalars['Int']>>;
  limit?: InputMaybe<Scalars['Int']>;
  search: Scalars['String'];
};


export type QueryTaskArgs = {
  id: Scalars['Int'];
};


export type QueryTasksPaginatedArgs = {
  orderDirection?: InputMaybe<Scalars['String']>;
  orderField?: InputMaybe<Scalars['String']>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  payloadFieldsFilter?: InputMaybe<Array<FilterPayloadFieldInput>>;
  search?: InputMaybe<Scalars['String']>;
  taskFieldsFilter: TaskFieldsFilterInput;
};


export type QueryUsersPaginatedArgs = {
  limit: Scalars['Int'];
  paginationToken?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
};

/** Input for creating or updating tasks */
export type SetGroupsForUserInput = {
  groups: Array<CognitoGroups>;
  userId: Scalars['Int'];
};

export type SimpleResponse = {
  __typename?: 'SimpleResponse';
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

/** Input for creating or updating tasks */
export type SubtaskInput = {
  content: Scalars['String'];
  dueDate?: InputMaybe<Scalars['DateTime']>;
  parentTaskId: Scalars['Int'];
  tenantId?: InputMaybe<Scalars['Int']>;
};

export type Task = {
  __typename?: 'Task';
  attachments?: Maybe<Array<Attachment>>;
  commentCount?: Maybe<Scalars['Int']>;
  comments?: Maybe<Array<Comment>>;
  completionDate?: Maybe<Scalars['DateTime']>;
  content: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  owners?: Maybe<Array<User>>;
  parentTask?: Maybe<Task>;
  parentTaskId?: Maybe<Scalars['Int']>;
  payload: Scalars['JSON'];
  status?: Maybe<TaskStatuses>;
  subtasks?: Maybe<Array<Task>>;
  taskActions: Array<TaskActions>;
  taskTypeId: Scalars['Int'];
  tenant?: Maybe<Tenant>;
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId: Scalars['Int'];
};

/** Actions that can be performed on the current task entity */
export enum TaskActions {
  AdditionalRoomShell = 'ADDITIONAL_ROOM_SHELL',
  Approve = 'APPROVE',
  Begin = 'BEGIN',
  Block = 'BLOCK',
  Cancel = 'CANCEL',
  Enhance = 'ENHANCE',
  Revise = 'REVISE',
  Unblock = 'UNBLOCK'
}

/** Input for filtering through task fields */
export type TaskFieldsFilterInput = {
  ownerId?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<TaskStatuses>;
  tenantId?: InputMaybe<Scalars['Int']>;
  typeId?: InputMaybe<Scalars['Int']>;
};

export type TaskPagination = {
  __typename?: 'TaskPagination';
  itemCount: Scalars['Float'];
  items: Array<Task>;
  pageNumber: Scalars['Float'];
  pageSize: Scalars['Float'];
};

/** Statuses for the task entity */
export enum TaskStatuses {
  Available = 'Available',
  Blocked = 'Blocked',
  Cancelled = 'Cancelled',
  Complete = 'Complete',
  Error = 'Error',
  HavenlyReview = 'HavenlyReview',
  InProgress = 'InProgress',
  OnHold = 'OnHold',
  Pending = 'Pending'
}

export type TaskType = {
  __typename?: 'TaskType';
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type Tenant = {
  __typename?: 'Tenant';
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** Input for creating or updating tasks */
export type UpdateTaskInput = {
  attachments?: InputMaybe<Array<Scalars['String']>>;
  content?: InputMaybe<Scalars['String']>;
  dueDate?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  payload?: InputMaybe<Scalars['JSON']>;
  status?: InputMaybe<TaskStatuses>;
  taskTypeId?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  cognitoUsername: Scalars['String'];
  comments?: Maybe<Array<Comment>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  /** Get the role for the authenticated user */
  groups?: Maybe<Array<CognitoGroups>>;
  id: Scalars['Int'];
  name: Scalars['String'];
  tasks?: Maybe<Array<Task>>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UserPagination = {
  __typename?: 'UserPagination';
  items: Array<User>;
  limit: Scalars['Float'];
  paginationToken?: Maybe<Scalars['String']>;
};

export type BasicCommentFragment = { __typename?: 'Comment', createdAt?: any | null | undefined, updatedAt?: any | null | undefined, id: number, userId: number, taskId: number, content: string };

export type BasicTaskFragment = { __typename?: 'Task', createdAt?: any | null | undefined, updatedAt?: any | null | undefined, id: number, taskTypeId: number, parentTaskId?: number | null | undefined, status?: TaskStatuses | null | undefined, title: string, content: string, payload: any, dueDate?: any | null | undefined, owners?: Array<{ __typename?: 'User', id: number, name: string, email: string, groups?: Array<CognitoGroups> | null | undefined, cognitoUsername: string }> | null | undefined };

export type BasicUserFragment = { __typename?: 'User', id: number, name: string, email: string, groups?: Array<CognitoGroups> | null | undefined, cognitoUsername: string };

export type AssignOwnersToTaskMutationVariables = Exact<{
  data: AssignUnassignTaskOwnersInput;
}>;


export type AssignOwnersToTaskMutation = { __typename?: 'Mutation', assignOwnersToTask: { __typename?: 'Task', createdAt?: any | null | undefined, updatedAt?: any | null | undefined, id: number, taskTypeId: number, parentTaskId?: number | null | undefined, status?: TaskStatuses | null | undefined, title: string, content: string, payload: any, dueDate?: any | null | undefined, owners?: Array<{ __typename?: 'User', id: number, name: string, email: string, groups?: Array<CognitoGroups> | null | undefined, cognitoUsername: string }> | null | undefined } };

export type CreateCommentMutationVariables = Exact<{
  data: CreateCommentInput;
}>;


export type CreateCommentMutation = { __typename?: 'Mutation', createComment: { __typename?: 'Comment', createdAt?: any | null | undefined, updatedAt?: any | null | undefined, id: number, userId: number, taskId: number, content: string, user: { __typename?: 'User', id: number, name: string, email: string, groups?: Array<CognitoGroups> | null | undefined, cognitoUsername: string } } };

export type CreateSubtaskMutationVariables = Exact<{
  data: SubtaskInput;
}>;


export type CreateSubtaskMutation = { __typename?: 'Mutation', createSubtask?: { __typename?: 'Task', createdAt?: any | null | undefined, updatedAt?: any | null | undefined, id: number, taskTypeId: number, parentTaskId?: number | null | undefined, status?: TaskStatuses | null | undefined, title: string, content: string, payload: any, dueDate?: any | null | undefined, subtasks?: Array<{ __typename?: 'Task', id: number, title: string, content: string }> | null | undefined, owners?: Array<{ __typename?: 'User', id: number, name: string, email: string, groups?: Array<CognitoGroups> | null | undefined, cognitoUsername: string }> | null | undefined } | null | undefined };

export type CreateTaskMutationVariables = Exact<{
  data: CreateTaskInput;
}>;


export type CreateTaskMutation = { __typename?: 'Mutation', createTask: { __typename?: 'Task', createdAt?: any | null | undefined, updatedAt?: any | null | undefined, id: number, taskTypeId: number, parentTaskId?: number | null | undefined, status?: TaskStatuses | null | undefined, title: string, content: string, payload: any, dueDate?: any | null | undefined, attachments?: Array<{ __typename?: 'Attachment', id: number, url: string }> | null | undefined, owners?: Array<{ __typename?: 'User', id: number, name: string, email: string, groups?: Array<CognitoGroups> | null | undefined, cognitoUsername: string }> | null | undefined } };

export type MassAssignDueDateToTasksMutationVariables = Exact<{
  data: MassAssignDueDateToTasksInput;
}>;


export type MassAssignDueDateToTasksMutation = { __typename?: 'Mutation', massAssignDueDateToTasks: Array<{ __typename?: 'Task', id: number, dueDate?: any | null | undefined }> };

export type MassAssignOwnersToTasksMutationVariables = Exact<{
  data: MassAssignOwnersToTasksInput;
}>;


export type MassAssignOwnersToTasksMutation = { __typename?: 'Mutation', massAssignOwnersToTasks: Array<{ __typename?: 'Task', createdAt?: any | null | undefined, updatedAt?: any | null | undefined, id: number, taskTypeId: number, parentTaskId?: number | null | undefined, status?: TaskStatuses | null | undefined, title: string, content: string, payload: any, dueDate?: any | null | undefined, owners?: Array<{ __typename?: 'User', id: number, name: string, email: string, groups?: Array<CognitoGroups> | null | undefined, cognitoUsername: string }> | null | undefined }> };

export type MassAssignStatusToTasksMutationVariables = Exact<{
  data: MassAssignStatusToTasksInput;
}>;


export type MassAssignStatusToTasksMutation = { __typename?: 'Mutation', massAssignStatusToTasks: Array<{ __typename?: 'Task', createdAt?: any | null | undefined, updatedAt?: any | null | undefined, id: number, taskTypeId: number, parentTaskId?: number | null | undefined, status?: TaskStatuses | null | undefined, title: string, content: string, payload: any, dueDate?: any | null | undefined, owners?: Array<{ __typename?: 'User', id: number, name: string, email: string, groups?: Array<CognitoGroups> | null | undefined, cognitoUsername: string }> | null | undefined }> };

export type SetGroupsForUserMutationVariables = Exact<{
  data: SetGroupsForUserInput;
}>;


export type SetGroupsForUserMutation = { __typename?: 'Mutation', setGroupsForUser: { __typename?: 'User', id: number, name: string, email: string, groups?: Array<CognitoGroups> | null | undefined, cognitoUsername: string } };

export type TaskActionReturnFragment = { __typename?: 'Task', id: number, status?: TaskStatuses | null | undefined };

export type RevisionApproveTaskMutationVariables = Exact<{
  taskId: Scalars['ID'];
}>;


export type RevisionApproveTaskMutation = { __typename?: 'Mutation', revisionApproveTask: { __typename?: 'Task', id: number, status?: TaskStatuses | null | undefined } };

export type BlockTaskMutationVariables = Exact<{
  taskId: Scalars['ID'];
  comment: Scalars['String'];
}>;


export type BlockTaskMutation = { __typename?: 'Mutation', blockTask: { __typename?: 'Task', id: number, status?: TaskStatuses | null | undefined } };

export type CancelTaskMutationVariables = Exact<{
  taskId: Scalars['ID'];
}>;


export type CancelTaskMutation = { __typename?: 'Mutation', cancelTask: { __typename?: 'Task', id: number, status?: TaskStatuses | null | undefined } };

export type UnblockTaskMutationVariables = Exact<{
  taskId: Scalars['ID'];
  comment: Scalars['String'];
}>;


export type UnblockTaskMutation = { __typename?: 'Mutation', unblockTask: { __typename?: 'Task', id: number, status?: TaskStatuses | null | undefined } };

export type RevisionApproveEnhancementTaskMutationVariables = Exact<{
  taskId: Scalars['ID'];
}>;


export type RevisionApproveEnhancementTaskMutation = { __typename?: 'Mutation', revisionApproveEnhancementTask: { __typename?: 'Task', id: number, status?: TaskStatuses | null | undefined } };

export type AdditionalRoomShellTaskMutationVariables = Exact<{
  taskId: Scalars['ID'];
}>;


export type AdditionalRoomShellTaskMutation = { __typename?: 'Mutation', additionalRoomShellTask: { __typename?: 'Task', id: number, status?: TaskStatuses | null | undefined } };

export type BeginTaskMutationVariables = Exact<{
  taskId: Scalars['ID'];
}>;


export type BeginTaskMutation = { __typename?: 'Mutation', beginTask: { __typename?: 'Task', id: number, status?: TaskStatuses | null | undefined } };

export type ReviseTaskMutationVariables = Exact<{
  taskId: Scalars['ID'];
  comment: Scalars['String'];
}>;


export type ReviseTaskMutation = { __typename?: 'Mutation', reviseTask: { __typename?: 'Task', id: number, status?: TaskStatuses | null | undefined } };

export type TriggerRoomShellRoomImagesUpdateMutationVariables = Exact<{
  roomShellRequestId: Scalars['Int'];
}>;


export type TriggerRoomShellRoomImagesUpdateMutation = { __typename?: 'Mutation', triggerRoomShellRoomImagesUpdate: { __typename?: 'SimpleResponse', success: boolean, message: string } };

export type UnassignOwnersFromTaskMutationVariables = Exact<{
  data: AssignUnassignTaskOwnersInput;
}>;


export type UnassignOwnersFromTaskMutation = { __typename?: 'Mutation', unassignOwnersFromTask: { __typename?: 'Task', createdAt?: any | null | undefined, updatedAt?: any | null | undefined, id: number, taskTypeId: number, parentTaskId?: number | null | undefined, status?: TaskStatuses | null | undefined, title: string, content: string, payload: any, dueDate?: any | null | undefined, owners?: Array<{ __typename?: 'User', id: number, name: string, email: string, groups?: Array<CognitoGroups> | null | undefined, cognitoUsername: string }> | null | undefined } };

export type UpdateTaskMutationVariables = Exact<{
  data: UpdateTaskInput;
}>;


export type UpdateTaskMutation = { __typename?: 'Mutation', updateTask: { __typename?: 'Task', createdAt?: any | null | undefined, updatedAt?: any | null | undefined, id: number, taskTypeId: number, parentTaskId?: number | null | undefined, status?: TaskStatuses | null | undefined, title: string, content: string, payload: any, dueDate?: any | null | undefined, attachments?: Array<{ __typename?: 'Attachment', id: number, url: string }> | null | undefined, owners?: Array<{ __typename?: 'User', id: number, name: string, email: string, groups?: Array<CognitoGroups> | null | undefined, cognitoUsername: string }> | null | undefined } };

export type CommentsByTaskIdQueryVariables = Exact<{
  taskId: Scalars['Int'];
}>;


export type CommentsByTaskIdQuery = { __typename?: 'Query', commentsByTaskId?: Array<{ __typename?: 'Comment', createdAt?: any | null | undefined, updatedAt?: any | null | undefined, id: number, userId: number, taskId: number, content: string, user: { __typename?: 'User', id: number, name: string, email: string, groups?: Array<CognitoGroups> | null | undefined, cognitoUsername: string } }> | null | undefined };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me: { __typename?: 'User', id: number, name: string, email: string, groups?: Array<CognitoGroups> | null | undefined, cognitoUsername: string } };

export type SearchUsersQueryVariables = Exact<{
  search: Scalars['String'];
  excludeUserIds?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;


export type SearchUsersQuery = { __typename?: 'Query', searchUsers: Array<{ __typename?: 'User', id: number, name: string, email: string, groups?: Array<CognitoGroups> | null | undefined, cognitoUsername: string }> };

export type TaskQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type TaskQuery = { __typename?: 'Query', task: { __typename?: 'Task', createdAt?: any | null | undefined, updatedAt?: any | null | undefined, id: number, taskTypeId: number, parentTaskId?: number | null | undefined, status?: TaskStatuses | null | undefined, title: string, content: string, payload: any, dueDate?: any | null | undefined, attachments?: Array<{ __typename?: 'Attachment', id: number, url: string }> | null | undefined, subtasks?: Array<{ __typename?: 'Task', createdAt?: any | null | undefined, updatedAt?: any | null | undefined, id: number, taskTypeId: number, parentTaskId?: number | null | undefined, status?: TaskStatuses | null | undefined, title: string, content: string, payload: any, dueDate?: any | null | undefined, owners?: Array<{ __typename?: 'User', id: number, name: string, email: string, groups?: Array<CognitoGroups> | null | undefined, cognitoUsername: string }> | null | undefined }> | null | undefined, owners?: Array<{ __typename?: 'User', id: number, name: string, email: string, groups?: Array<CognitoGroups> | null | undefined, cognitoUsername: string }> | null | undefined } };

export type TaskDetailsQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type TaskDetailsQuery = { __typename?: 'Query', task: { __typename?: 'Task', taskActions: Array<TaskActions>, createdAt?: any | null | undefined, updatedAt?: any | null | undefined, id: number, taskTypeId: number, parentTaskId?: number | null | undefined, status?: TaskStatuses | null | undefined, title: string, content: string, payload: any, dueDate?: any | null | undefined, attachments?: Array<{ __typename?: 'Attachment', id: number, url: string }> | null | undefined, parentTask?: { __typename?: 'Task', payload: any } | null | undefined, subtasks?: Array<{ __typename?: 'Task', createdAt?: any | null | undefined, updatedAt?: any | null | undefined, id: number, taskTypeId: number, parentTaskId?: number | null | undefined, status?: TaskStatuses | null | undefined, title: string, content: string, payload: any, dueDate?: any | null | undefined, owners?: Array<{ __typename?: 'User', id: number, name: string, email: string, groups?: Array<CognitoGroups> | null | undefined, cognitoUsername: string }> | null | undefined }> | null | undefined, owners?: Array<{ __typename?: 'User', id: number, name: string, email: string, groups?: Array<CognitoGroups> | null | undefined, cognitoUsername: string }> | null | undefined } };

export type TaskTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type TaskTypesQuery = { __typename?: 'Query', taskTypes?: Array<{ __typename?: 'TaskType', id: number, name: string }> | null | undefined };

export type TasksPaginatedQueryVariables = Exact<{
  pageSize: Scalars['Int'];
  pageNumber: Scalars['Int'];
  taskFieldsFilter: TaskFieldsFilterInput;
  payloadFieldsFilter?: InputMaybe<Array<FilterPayloadFieldInput> | FilterPayloadFieldInput>;
  search?: InputMaybe<Scalars['String']>;
  orderField?: InputMaybe<Scalars['String']>;
  orderDirection?: InputMaybe<Scalars['String']>;
}>;


export type TasksPaginatedQuery = { __typename?: 'Query', tasksPaginated: { __typename?: 'TaskPagination', itemCount: number, pageNumber: number, pageSize: number, items: Array<{ __typename?: 'Task', commentCount?: number | null | undefined, createdAt?: any | null | undefined, updatedAt?: any | null | undefined, id: number, taskTypeId: number, parentTaskId?: number | null | undefined, status?: TaskStatuses | null | undefined, title: string, content: string, payload: any, dueDate?: any | null | undefined, vendor?: { __typename?: 'Tenant', id: number } | null | undefined, owners?: Array<{ __typename?: 'User', id: number, name: string, email: string, groups?: Array<CognitoGroups> | null | undefined, cognitoUsername: string }> | null | undefined }> } };

export type UsersPaginatedQueryVariables = Exact<{
  limit: Scalars['Int'];
  search?: InputMaybe<Scalars['String']>;
  paginationToken?: InputMaybe<Scalars['String']>;
}>;


export type UsersPaginatedQuery = { __typename?: 'Query', usersPaginated: { __typename?: 'UserPagination', paginationToken?: string | null | undefined, limit: number, items: Array<{ __typename?: 'User', id: number, name: string, email: string, groups?: Array<CognitoGroups> | null | undefined, cognitoUsername: string }> } };

export type CreateUserMutationVariables = Exact<{
  name: Scalars['String'];
  email: Scalars['String'];
  group: CognitoGroups;
  tenantId?: InputMaybe<Scalars['ID']>;
}>;


export type CreateUserMutation = { __typename?: 'Mutation', createUser: { __typename?: 'User', id: number, email: string, groups?: Array<CognitoGroups> | null | undefined, cognitoUsername: string } };

export const BasicCommentFragmentDoc = gql`
    fragment BasicComment on Comment {
  createdAt
  updatedAt
  id
  userId
  taskId
  content
}
    `;
export const BasicUserFragmentDoc = gql`
    fragment BasicUser on User {
  id
  name
  email
  groups
  cognitoUsername
}
    `;
export const BasicTaskFragmentDoc = gql`
    fragment BasicTask on Task {
  createdAt
  updatedAt
  id
  taskTypeId
  parentTaskId
  status
  title
  content
  payload
  dueDate
  createdAt
  owners {
    ...BasicUser
  }
}
    ${BasicUserFragmentDoc}`;
export const TaskActionReturnFragmentDoc = gql`
    fragment TaskActionReturn on Task {
  id
  status
}
    `;
export const AssignOwnersToTaskDocument = gql`
    mutation AssignOwnersToTask($data: AssignUnassignTaskOwnersInput!) {
  assignOwnersToTask(data: $data) {
    ...BasicTask
  }
}
    ${BasicTaskFragmentDoc}`;
export type AssignOwnersToTaskMutationFn = Apollo.MutationFunction<AssignOwnersToTaskMutation, AssignOwnersToTaskMutationVariables>;

/**
 * __useAssignOwnersToTaskMutation__
 *
 * To run a mutation, you first call `useAssignOwnersToTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignOwnersToTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignOwnersToTaskMutation, { data, loading, error }] = useAssignOwnersToTaskMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAssignOwnersToTaskMutation(baseOptions?: Apollo.MutationHookOptions<AssignOwnersToTaskMutation, AssignOwnersToTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AssignOwnersToTaskMutation, AssignOwnersToTaskMutationVariables>(AssignOwnersToTaskDocument, options);
      }
export type AssignOwnersToTaskMutationHookResult = ReturnType<typeof useAssignOwnersToTaskMutation>;
export type AssignOwnersToTaskMutationResult = Apollo.MutationResult<AssignOwnersToTaskMutation>;
export type AssignOwnersToTaskMutationOptions = Apollo.BaseMutationOptions<AssignOwnersToTaskMutation, AssignOwnersToTaskMutationVariables>;
export const CreateCommentDocument = gql`
    mutation CreateComment($data: CreateCommentInput!) {
  createComment(data: $data) {
    ...BasicComment
    user {
      ...BasicUser
    }
  }
}
    ${BasicCommentFragmentDoc}
${BasicUserFragmentDoc}`;
export type CreateCommentMutationFn = Apollo.MutationFunction<CreateCommentMutation, CreateCommentMutationVariables>;

/**
 * __useCreateCommentMutation__
 *
 * To run a mutation, you first call `useCreateCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCommentMutation, { data, loading, error }] = useCreateCommentMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateCommentMutation(baseOptions?: Apollo.MutationHookOptions<CreateCommentMutation, CreateCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCommentMutation, CreateCommentMutationVariables>(CreateCommentDocument, options);
      }
export type CreateCommentMutationHookResult = ReturnType<typeof useCreateCommentMutation>;
export type CreateCommentMutationResult = Apollo.MutationResult<CreateCommentMutation>;
export type CreateCommentMutationOptions = Apollo.BaseMutationOptions<CreateCommentMutation, CreateCommentMutationVariables>;
export const CreateSubtaskDocument = gql`
    mutation CreateSubtask($data: SubtaskInput!) {
  createSubtask(data: $data) {
    ...BasicTask
    subtasks {
      id
      title
      content
    }
  }
}
    ${BasicTaskFragmentDoc}`;
export type CreateSubtaskMutationFn = Apollo.MutationFunction<CreateSubtaskMutation, CreateSubtaskMutationVariables>;

/**
 * __useCreateSubtaskMutation__
 *
 * To run a mutation, you first call `useCreateSubtaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubtaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubtaskMutation, { data, loading, error }] = useCreateSubtaskMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateSubtaskMutation(baseOptions?: Apollo.MutationHookOptions<CreateSubtaskMutation, CreateSubtaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSubtaskMutation, CreateSubtaskMutationVariables>(CreateSubtaskDocument, options);
      }
export type CreateSubtaskMutationHookResult = ReturnType<typeof useCreateSubtaskMutation>;
export type CreateSubtaskMutationResult = Apollo.MutationResult<CreateSubtaskMutation>;
export type CreateSubtaskMutationOptions = Apollo.BaseMutationOptions<CreateSubtaskMutation, CreateSubtaskMutationVariables>;
export const CreateTaskDocument = gql`
    mutation CreateTask($data: CreateTaskInput!) {
  createTask(data: $data) {
    ...BasicTask
    attachments {
      id
      url
    }
  }
}
    ${BasicTaskFragmentDoc}`;
export type CreateTaskMutationFn = Apollo.MutationFunction<CreateTaskMutation, CreateTaskMutationVariables>;

/**
 * __useCreateTaskMutation__
 *
 * To run a mutation, you first call `useCreateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTaskMutation, { data, loading, error }] = useCreateTaskMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateTaskMutation(baseOptions?: Apollo.MutationHookOptions<CreateTaskMutation, CreateTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTaskMutation, CreateTaskMutationVariables>(CreateTaskDocument, options);
      }
export type CreateTaskMutationHookResult = ReturnType<typeof useCreateTaskMutation>;
export type CreateTaskMutationResult = Apollo.MutationResult<CreateTaskMutation>;
export type CreateTaskMutationOptions = Apollo.BaseMutationOptions<CreateTaskMutation, CreateTaskMutationVariables>;
export const MassAssignDueDateToTasksDocument = gql`
    mutation MassAssignDueDateToTasks($data: MassAssignDueDateToTasksInput!) {
  massAssignDueDateToTasks(data: $data) {
    id
    dueDate
  }
}
    `;
export type MassAssignDueDateToTasksMutationFn = Apollo.MutationFunction<MassAssignDueDateToTasksMutation, MassAssignDueDateToTasksMutationVariables>;

/**
 * __useMassAssignDueDateToTasksMutation__
 *
 * To run a mutation, you first call `useMassAssignDueDateToTasksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMassAssignDueDateToTasksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [massAssignDueDateToTasksMutation, { data, loading, error }] = useMassAssignDueDateToTasksMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useMassAssignDueDateToTasksMutation(baseOptions?: Apollo.MutationHookOptions<MassAssignDueDateToTasksMutation, MassAssignDueDateToTasksMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MassAssignDueDateToTasksMutation, MassAssignDueDateToTasksMutationVariables>(MassAssignDueDateToTasksDocument, options);
      }
export type MassAssignDueDateToTasksMutationHookResult = ReturnType<typeof useMassAssignDueDateToTasksMutation>;
export type MassAssignDueDateToTasksMutationResult = Apollo.MutationResult<MassAssignDueDateToTasksMutation>;
export type MassAssignDueDateToTasksMutationOptions = Apollo.BaseMutationOptions<MassAssignDueDateToTasksMutation, MassAssignDueDateToTasksMutationVariables>;
export const MassAssignOwnersToTasksDocument = gql`
    mutation MassAssignOwnersToTasks($data: MassAssignOwnersToTasksInput!) {
  massAssignOwnersToTasks(data: $data) {
    ...BasicTask
  }
}
    ${BasicTaskFragmentDoc}`;
export type MassAssignOwnersToTasksMutationFn = Apollo.MutationFunction<MassAssignOwnersToTasksMutation, MassAssignOwnersToTasksMutationVariables>;

/**
 * __useMassAssignOwnersToTasksMutation__
 *
 * To run a mutation, you first call `useMassAssignOwnersToTasksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMassAssignOwnersToTasksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [massAssignOwnersToTasksMutation, { data, loading, error }] = useMassAssignOwnersToTasksMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useMassAssignOwnersToTasksMutation(baseOptions?: Apollo.MutationHookOptions<MassAssignOwnersToTasksMutation, MassAssignOwnersToTasksMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MassAssignOwnersToTasksMutation, MassAssignOwnersToTasksMutationVariables>(MassAssignOwnersToTasksDocument, options);
      }
export type MassAssignOwnersToTasksMutationHookResult = ReturnType<typeof useMassAssignOwnersToTasksMutation>;
export type MassAssignOwnersToTasksMutationResult = Apollo.MutationResult<MassAssignOwnersToTasksMutation>;
export type MassAssignOwnersToTasksMutationOptions = Apollo.BaseMutationOptions<MassAssignOwnersToTasksMutation, MassAssignOwnersToTasksMutationVariables>;
export const MassAssignStatusToTasksDocument = gql`
    mutation MassAssignStatusToTasks($data: MassAssignStatusToTasksInput!) {
  massAssignStatusToTasks(data: $data) {
    ...BasicTask
  }
}
    ${BasicTaskFragmentDoc}`;
export type MassAssignStatusToTasksMutationFn = Apollo.MutationFunction<MassAssignStatusToTasksMutation, MassAssignStatusToTasksMutationVariables>;

/**
 * __useMassAssignStatusToTasksMutation__
 *
 * To run a mutation, you first call `useMassAssignStatusToTasksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMassAssignStatusToTasksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [massAssignStatusToTasksMutation, { data, loading, error }] = useMassAssignStatusToTasksMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useMassAssignStatusToTasksMutation(baseOptions?: Apollo.MutationHookOptions<MassAssignStatusToTasksMutation, MassAssignStatusToTasksMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MassAssignStatusToTasksMutation, MassAssignStatusToTasksMutationVariables>(MassAssignStatusToTasksDocument, options);
      }
export type MassAssignStatusToTasksMutationHookResult = ReturnType<typeof useMassAssignStatusToTasksMutation>;
export type MassAssignStatusToTasksMutationResult = Apollo.MutationResult<MassAssignStatusToTasksMutation>;
export type MassAssignStatusToTasksMutationOptions = Apollo.BaseMutationOptions<MassAssignStatusToTasksMutation, MassAssignStatusToTasksMutationVariables>;
export const SetGroupsForUserDocument = gql`
    mutation SetGroupsForUser($data: SetGroupsForUserInput!) {
  setGroupsForUser(data: $data) {
    ...BasicUser
  }
}
    ${BasicUserFragmentDoc}`;
export type SetGroupsForUserMutationFn = Apollo.MutationFunction<SetGroupsForUserMutation, SetGroupsForUserMutationVariables>;

/**
 * __useSetGroupsForUserMutation__
 *
 * To run a mutation, you first call `useSetGroupsForUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetGroupsForUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setGroupsForUserMutation, { data, loading, error }] = useSetGroupsForUserMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSetGroupsForUserMutation(baseOptions?: Apollo.MutationHookOptions<SetGroupsForUserMutation, SetGroupsForUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetGroupsForUserMutation, SetGroupsForUserMutationVariables>(SetGroupsForUserDocument, options);
      }
export type SetGroupsForUserMutationHookResult = ReturnType<typeof useSetGroupsForUserMutation>;
export type SetGroupsForUserMutationResult = Apollo.MutationResult<SetGroupsForUserMutation>;
export type SetGroupsForUserMutationOptions = Apollo.BaseMutationOptions<SetGroupsForUserMutation, SetGroupsForUserMutationVariables>;
export const RevisionApproveTaskDocument = gql`
    mutation RevisionApproveTask($taskId: ID!) {
  revisionApproveTask(taskId: $taskId) {
    ...TaskActionReturn
  }
}
    ${TaskActionReturnFragmentDoc}`;
export type RevisionApproveTaskMutationFn = Apollo.MutationFunction<RevisionApproveTaskMutation, RevisionApproveTaskMutationVariables>;

/**
 * __useRevisionApproveTaskMutation__
 *
 * To run a mutation, you first call `useRevisionApproveTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevisionApproveTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revisionApproveTaskMutation, { data, loading, error }] = useRevisionApproveTaskMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useRevisionApproveTaskMutation(baseOptions?: Apollo.MutationHookOptions<RevisionApproveTaskMutation, RevisionApproveTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RevisionApproveTaskMutation, RevisionApproveTaskMutationVariables>(RevisionApproveTaskDocument, options);
      }
export type RevisionApproveTaskMutationHookResult = ReturnType<typeof useRevisionApproveTaskMutation>;
export type RevisionApproveTaskMutationResult = Apollo.MutationResult<RevisionApproveTaskMutation>;
export type RevisionApproveTaskMutationOptions = Apollo.BaseMutationOptions<RevisionApproveTaskMutation, RevisionApproveTaskMutationVariables>;
export const BlockTaskDocument = gql`
    mutation BlockTask($taskId: ID!, $comment: String!) {
  blockTask(taskId: $taskId, comment: $comment) {
    ...TaskActionReturn
  }
}
    ${TaskActionReturnFragmentDoc}`;
export type BlockTaskMutationFn = Apollo.MutationFunction<BlockTaskMutation, BlockTaskMutationVariables>;

/**
 * __useBlockTaskMutation__
 *
 * To run a mutation, you first call `useBlockTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBlockTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [blockTaskMutation, { data, loading, error }] = useBlockTaskMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useBlockTaskMutation(baseOptions?: Apollo.MutationHookOptions<BlockTaskMutation, BlockTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BlockTaskMutation, BlockTaskMutationVariables>(BlockTaskDocument, options);
      }
export type BlockTaskMutationHookResult = ReturnType<typeof useBlockTaskMutation>;
export type BlockTaskMutationResult = Apollo.MutationResult<BlockTaskMutation>;
export type BlockTaskMutationOptions = Apollo.BaseMutationOptions<BlockTaskMutation, BlockTaskMutationVariables>;
export const CancelTaskDocument = gql`
    mutation CancelTask($taskId: ID!) {
  cancelTask(taskId: $taskId) {
    ...TaskActionReturn
  }
}
    ${TaskActionReturnFragmentDoc}`;
export type CancelTaskMutationFn = Apollo.MutationFunction<CancelTaskMutation, CancelTaskMutationVariables>;

/**
 * __useCancelTaskMutation__
 *
 * To run a mutation, you first call `useCancelTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelTaskMutation, { data, loading, error }] = useCancelTaskMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useCancelTaskMutation(baseOptions?: Apollo.MutationHookOptions<CancelTaskMutation, CancelTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelTaskMutation, CancelTaskMutationVariables>(CancelTaskDocument, options);
      }
export type CancelTaskMutationHookResult = ReturnType<typeof useCancelTaskMutation>;
export type CancelTaskMutationResult = Apollo.MutationResult<CancelTaskMutation>;
export type CancelTaskMutationOptions = Apollo.BaseMutationOptions<CancelTaskMutation, CancelTaskMutationVariables>;
export const UnblockTaskDocument = gql`
    mutation UnblockTask($taskId: ID!, $comment: String!) {
  unblockTask(taskId: $taskId, comment: $comment) {
    ...TaskActionReturn
  }
}
    ${TaskActionReturnFragmentDoc}`;
export type UnblockTaskMutationFn = Apollo.MutationFunction<UnblockTaskMutation, UnblockTaskMutationVariables>;

/**
 * __useUnblockTaskMutation__
 *
 * To run a mutation, you first call `useUnblockTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnblockTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unblockTaskMutation, { data, loading, error }] = useUnblockTaskMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useUnblockTaskMutation(baseOptions?: Apollo.MutationHookOptions<UnblockTaskMutation, UnblockTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnblockTaskMutation, UnblockTaskMutationVariables>(UnblockTaskDocument, options);
      }
export type UnblockTaskMutationHookResult = ReturnType<typeof useUnblockTaskMutation>;
export type UnblockTaskMutationResult = Apollo.MutationResult<UnblockTaskMutation>;
export type UnblockTaskMutationOptions = Apollo.BaseMutationOptions<UnblockTaskMutation, UnblockTaskMutationVariables>;
export const RevisionApproveEnhancementTaskDocument = gql`
    mutation RevisionApproveEnhancementTask($taskId: ID!) {
  revisionApproveEnhancementTask(taskId: $taskId) {
    ...TaskActionReturn
  }
}
    ${TaskActionReturnFragmentDoc}`;
export type RevisionApproveEnhancementTaskMutationFn = Apollo.MutationFunction<RevisionApproveEnhancementTaskMutation, RevisionApproveEnhancementTaskMutationVariables>;

/**
 * __useRevisionApproveEnhancementTaskMutation__
 *
 * To run a mutation, you first call `useRevisionApproveEnhancementTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevisionApproveEnhancementTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revisionApproveEnhancementTaskMutation, { data, loading, error }] = useRevisionApproveEnhancementTaskMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useRevisionApproveEnhancementTaskMutation(baseOptions?: Apollo.MutationHookOptions<RevisionApproveEnhancementTaskMutation, RevisionApproveEnhancementTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RevisionApproveEnhancementTaskMutation, RevisionApproveEnhancementTaskMutationVariables>(RevisionApproveEnhancementTaskDocument, options);
      }
export type RevisionApproveEnhancementTaskMutationHookResult = ReturnType<typeof useRevisionApproveEnhancementTaskMutation>;
export type RevisionApproveEnhancementTaskMutationResult = Apollo.MutationResult<RevisionApproveEnhancementTaskMutation>;
export type RevisionApproveEnhancementTaskMutationOptions = Apollo.BaseMutationOptions<RevisionApproveEnhancementTaskMutation, RevisionApproveEnhancementTaskMutationVariables>;
export const AdditionalRoomShellTaskDocument = gql`
    mutation AdditionalRoomShellTask($taskId: ID!) {
  additionalRoomShellTask(taskId: $taskId) {
    ...TaskActionReturn
  }
}
    ${TaskActionReturnFragmentDoc}`;
export type AdditionalRoomShellTaskMutationFn = Apollo.MutationFunction<AdditionalRoomShellTaskMutation, AdditionalRoomShellTaskMutationVariables>;

/**
 * __useAdditionalRoomShellTaskMutation__
 *
 * To run a mutation, you first call `useAdditionalRoomShellTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdditionalRoomShellTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [additionalRoomShellTaskMutation, { data, loading, error }] = useAdditionalRoomShellTaskMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useAdditionalRoomShellTaskMutation(baseOptions?: Apollo.MutationHookOptions<AdditionalRoomShellTaskMutation, AdditionalRoomShellTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdditionalRoomShellTaskMutation, AdditionalRoomShellTaskMutationVariables>(AdditionalRoomShellTaskDocument, options);
      }
export type AdditionalRoomShellTaskMutationHookResult = ReturnType<typeof useAdditionalRoomShellTaskMutation>;
export type AdditionalRoomShellTaskMutationResult = Apollo.MutationResult<AdditionalRoomShellTaskMutation>;
export type AdditionalRoomShellTaskMutationOptions = Apollo.BaseMutationOptions<AdditionalRoomShellTaskMutation, AdditionalRoomShellTaskMutationVariables>;
export const BeginTaskDocument = gql`
    mutation BeginTask($taskId: ID!) {
  beginTask(taskId: $taskId) {
    ...TaskActionReturn
  }
}
    ${TaskActionReturnFragmentDoc}`;
export type BeginTaskMutationFn = Apollo.MutationFunction<BeginTaskMutation, BeginTaskMutationVariables>;

/**
 * __useBeginTaskMutation__
 *
 * To run a mutation, you first call `useBeginTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBeginTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [beginTaskMutation, { data, loading, error }] = useBeginTaskMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useBeginTaskMutation(baseOptions?: Apollo.MutationHookOptions<BeginTaskMutation, BeginTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BeginTaskMutation, BeginTaskMutationVariables>(BeginTaskDocument, options);
      }
export type BeginTaskMutationHookResult = ReturnType<typeof useBeginTaskMutation>;
export type BeginTaskMutationResult = Apollo.MutationResult<BeginTaskMutation>;
export type BeginTaskMutationOptions = Apollo.BaseMutationOptions<BeginTaskMutation, BeginTaskMutationVariables>;
export const ReviseTaskDocument = gql`
    mutation ReviseTask($taskId: ID!, $comment: String!) {
  reviseTask(taskId: $taskId, comment: $comment) {
    ...TaskActionReturn
  }
}
    ${TaskActionReturnFragmentDoc}`;
export type ReviseTaskMutationFn = Apollo.MutationFunction<ReviseTaskMutation, ReviseTaskMutationVariables>;

/**
 * __useReviseTaskMutation__
 *
 * To run a mutation, you first call `useReviseTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReviseTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reviseTaskMutation, { data, loading, error }] = useReviseTaskMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useReviseTaskMutation(baseOptions?: Apollo.MutationHookOptions<ReviseTaskMutation, ReviseTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReviseTaskMutation, ReviseTaskMutationVariables>(ReviseTaskDocument, options);
      }
export type ReviseTaskMutationHookResult = ReturnType<typeof useReviseTaskMutation>;
export type ReviseTaskMutationResult = Apollo.MutationResult<ReviseTaskMutation>;
export type ReviseTaskMutationOptions = Apollo.BaseMutationOptions<ReviseTaskMutation, ReviseTaskMutationVariables>;
export const TriggerRoomShellRoomImagesUpdateDocument = gql`
    mutation TriggerRoomShellRoomImagesUpdate($roomShellRequestId: Int!) {
  triggerRoomShellRoomImagesUpdate(roomShellRequestId: $roomShellRequestId) {
    success
    message
  }
}
    `;
export type TriggerRoomShellRoomImagesUpdateMutationFn = Apollo.MutationFunction<TriggerRoomShellRoomImagesUpdateMutation, TriggerRoomShellRoomImagesUpdateMutationVariables>;

/**
 * __useTriggerRoomShellRoomImagesUpdateMutation__
 *
 * To run a mutation, you first call `useTriggerRoomShellRoomImagesUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTriggerRoomShellRoomImagesUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [triggerRoomShellRoomImagesUpdateMutation, { data, loading, error }] = useTriggerRoomShellRoomImagesUpdateMutation({
 *   variables: {
 *      roomShellRequestId: // value for 'roomShellRequestId'
 *   },
 * });
 */
export function useTriggerRoomShellRoomImagesUpdateMutation(baseOptions?: Apollo.MutationHookOptions<TriggerRoomShellRoomImagesUpdateMutation, TriggerRoomShellRoomImagesUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TriggerRoomShellRoomImagesUpdateMutation, TriggerRoomShellRoomImagesUpdateMutationVariables>(TriggerRoomShellRoomImagesUpdateDocument, options);
      }
export type TriggerRoomShellRoomImagesUpdateMutationHookResult = ReturnType<typeof useTriggerRoomShellRoomImagesUpdateMutation>;
export type TriggerRoomShellRoomImagesUpdateMutationResult = Apollo.MutationResult<TriggerRoomShellRoomImagesUpdateMutation>;
export type TriggerRoomShellRoomImagesUpdateMutationOptions = Apollo.BaseMutationOptions<TriggerRoomShellRoomImagesUpdateMutation, TriggerRoomShellRoomImagesUpdateMutationVariables>;
export const UnassignOwnersFromTaskDocument = gql`
    mutation UnassignOwnersFromTask($data: AssignUnassignTaskOwnersInput!) {
  unassignOwnersFromTask(data: $data) {
    ...BasicTask
  }
}
    ${BasicTaskFragmentDoc}`;
export type UnassignOwnersFromTaskMutationFn = Apollo.MutationFunction<UnassignOwnersFromTaskMutation, UnassignOwnersFromTaskMutationVariables>;

/**
 * __useUnassignOwnersFromTaskMutation__
 *
 * To run a mutation, you first call `useUnassignOwnersFromTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnassignOwnersFromTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unassignOwnersFromTaskMutation, { data, loading, error }] = useUnassignOwnersFromTaskMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUnassignOwnersFromTaskMutation(baseOptions?: Apollo.MutationHookOptions<UnassignOwnersFromTaskMutation, UnassignOwnersFromTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnassignOwnersFromTaskMutation, UnassignOwnersFromTaskMutationVariables>(UnassignOwnersFromTaskDocument, options);
      }
export type UnassignOwnersFromTaskMutationHookResult = ReturnType<typeof useUnassignOwnersFromTaskMutation>;
export type UnassignOwnersFromTaskMutationResult = Apollo.MutationResult<UnassignOwnersFromTaskMutation>;
export type UnassignOwnersFromTaskMutationOptions = Apollo.BaseMutationOptions<UnassignOwnersFromTaskMutation, UnassignOwnersFromTaskMutationVariables>;
export const UpdateTaskDocument = gql`
    mutation UpdateTask($data: UpdateTaskInput!) {
  updateTask(data: $data) {
    ...BasicTask
    attachments {
      id
      url
    }
  }
}
    ${BasicTaskFragmentDoc}`;
export type UpdateTaskMutationFn = Apollo.MutationFunction<UpdateTaskMutation, UpdateTaskMutationVariables>;

/**
 * __useUpdateTaskMutation__
 *
 * To run a mutation, you first call `useUpdateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskMutation, { data, loading, error }] = useUpdateTaskMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateTaskMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTaskMutation, UpdateTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTaskMutation, UpdateTaskMutationVariables>(UpdateTaskDocument, options);
      }
export type UpdateTaskMutationHookResult = ReturnType<typeof useUpdateTaskMutation>;
export type UpdateTaskMutationResult = Apollo.MutationResult<UpdateTaskMutation>;
export type UpdateTaskMutationOptions = Apollo.BaseMutationOptions<UpdateTaskMutation, UpdateTaskMutationVariables>;
export const CommentsByTaskIdDocument = gql`
    query CommentsByTaskId($taskId: Int!) {
  commentsByTaskId(taskId: $taskId) {
    ...BasicComment
    user {
      ...BasicUser
    }
  }
}
    ${BasicCommentFragmentDoc}
${BasicUserFragmentDoc}`;

/**
 * __useCommentsByTaskIdQuery__
 *
 * To run a query within a React component, call `useCommentsByTaskIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommentsByTaskIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommentsByTaskIdQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useCommentsByTaskIdQuery(baseOptions: Apollo.QueryHookOptions<CommentsByTaskIdQuery, CommentsByTaskIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CommentsByTaskIdQuery, CommentsByTaskIdQueryVariables>(CommentsByTaskIdDocument, options);
      }
export function useCommentsByTaskIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CommentsByTaskIdQuery, CommentsByTaskIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CommentsByTaskIdQuery, CommentsByTaskIdQueryVariables>(CommentsByTaskIdDocument, options);
        }
export type CommentsByTaskIdQueryHookResult = ReturnType<typeof useCommentsByTaskIdQuery>;
export type CommentsByTaskIdLazyQueryHookResult = ReturnType<typeof useCommentsByTaskIdLazyQuery>;
export type CommentsByTaskIdQueryResult = Apollo.QueryResult<CommentsByTaskIdQuery, CommentsByTaskIdQueryVariables>;
export const MeDocument = gql`
    query Me {
  me {
    ...BasicUser
  }
}
    ${BasicUserFragmentDoc}`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const SearchUsersDocument = gql`
    query SearchUsers($search: String!, $excludeUserIds: [Int!], $limit: Int) {
  searchUsers(search: $search, excludeUserIds: $excludeUserIds, limit: $limit) {
    ...BasicUser
  }
}
    ${BasicUserFragmentDoc}`;

/**
 * __useSearchUsersQuery__
 *
 * To run a query within a React component, call `useSearchUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchUsersQuery({
 *   variables: {
 *      search: // value for 'search'
 *      excludeUserIds: // value for 'excludeUserIds'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useSearchUsersQuery(baseOptions: Apollo.QueryHookOptions<SearchUsersQuery, SearchUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchUsersQuery, SearchUsersQueryVariables>(SearchUsersDocument, options);
      }
export function useSearchUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchUsersQuery, SearchUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchUsersQuery, SearchUsersQueryVariables>(SearchUsersDocument, options);
        }
export type SearchUsersQueryHookResult = ReturnType<typeof useSearchUsersQuery>;
export type SearchUsersLazyQueryHookResult = ReturnType<typeof useSearchUsersLazyQuery>;
export type SearchUsersQueryResult = Apollo.QueryResult<SearchUsersQuery, SearchUsersQueryVariables>;
export const TaskDocument = gql`
    query Task($id: Int!) {
  task(id: $id) {
    ...BasicTask
    attachments {
      id
      url
    }
    subtasks {
      ...BasicTask
    }
  }
}
    ${BasicTaskFragmentDoc}`;

/**
 * __useTaskQuery__
 *
 * To run a query within a React component, call `useTaskQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTaskQuery(baseOptions: Apollo.QueryHookOptions<TaskQuery, TaskQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TaskQuery, TaskQueryVariables>(TaskDocument, options);
      }
export function useTaskLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TaskQuery, TaskQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TaskQuery, TaskQueryVariables>(TaskDocument, options);
        }
export type TaskQueryHookResult = ReturnType<typeof useTaskQuery>;
export type TaskLazyQueryHookResult = ReturnType<typeof useTaskLazyQuery>;
export type TaskQueryResult = Apollo.QueryResult<TaskQuery, TaskQueryVariables>;
export const TaskDetailsDocument = gql`
    query TaskDetails($id: Int!) {
  task(id: $id) {
    ...BasicTask
    attachments {
      id
      url
    }
    parentTask {
      payload
    }
    subtasks {
      ...BasicTask
    }
    taskActions
  }
}
    ${BasicTaskFragmentDoc}`;

/**
 * __useTaskDetailsQuery__
 *
 * To run a query within a React component, call `useTaskDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTaskDetailsQuery(baseOptions: Apollo.QueryHookOptions<TaskDetailsQuery, TaskDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TaskDetailsQuery, TaskDetailsQueryVariables>(TaskDetailsDocument, options);
      }
export function useTaskDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TaskDetailsQuery, TaskDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TaskDetailsQuery, TaskDetailsQueryVariables>(TaskDetailsDocument, options);
        }
export type TaskDetailsQueryHookResult = ReturnType<typeof useTaskDetailsQuery>;
export type TaskDetailsLazyQueryHookResult = ReturnType<typeof useTaskDetailsLazyQuery>;
export type TaskDetailsQueryResult = Apollo.QueryResult<TaskDetailsQuery, TaskDetailsQueryVariables>;
export const TaskTypesDocument = gql`
    query TaskTypes {
  taskTypes {
    id
    name
  }
}
    `;

/**
 * __useTaskTypesQuery__
 *
 * To run a query within a React component, call `useTaskTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useTaskTypesQuery(baseOptions?: Apollo.QueryHookOptions<TaskTypesQuery, TaskTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TaskTypesQuery, TaskTypesQueryVariables>(TaskTypesDocument, options);
      }
export function useTaskTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TaskTypesQuery, TaskTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TaskTypesQuery, TaskTypesQueryVariables>(TaskTypesDocument, options);
        }
export type TaskTypesQueryHookResult = ReturnType<typeof useTaskTypesQuery>;
export type TaskTypesLazyQueryHookResult = ReturnType<typeof useTaskTypesLazyQuery>;
export type TaskTypesQueryResult = Apollo.QueryResult<TaskTypesQuery, TaskTypesQueryVariables>;
export const TasksPaginatedDocument = gql`
    query TasksPaginated($pageSize: Int!, $pageNumber: Int!, $taskFieldsFilter: TaskFieldsFilterInput!, $payloadFieldsFilter: [FilterPayloadFieldInput!], $search: String, $orderField: String, $orderDirection: String) {
  tasksPaginated(
    pageNumber: $pageNumber
    pageSize: $pageSize
    taskFieldsFilter: $taskFieldsFilter
    payloadFieldsFilter: $payloadFieldsFilter
    search: $search
    orderField: $orderField
    orderDirection: $orderDirection
  ) {
    itemCount
    pageNumber
    pageSize
    items {
      ...BasicTask
      commentCount
      vendor: tenant {
        id
      }
    }
  }
}
    ${BasicTaskFragmentDoc}`;

/**
 * __useTasksPaginatedQuery__
 *
 * To run a query within a React component, call `useTasksPaginatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useTasksPaginatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTasksPaginatedQuery({
 *   variables: {
 *      pageSize: // value for 'pageSize'
 *      pageNumber: // value for 'pageNumber'
 *      taskFieldsFilter: // value for 'taskFieldsFilter'
 *      payloadFieldsFilter: // value for 'payloadFieldsFilter'
 *      search: // value for 'search'
 *      orderField: // value for 'orderField'
 *      orderDirection: // value for 'orderDirection'
 *   },
 * });
 */
export function useTasksPaginatedQuery(baseOptions: Apollo.QueryHookOptions<TasksPaginatedQuery, TasksPaginatedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TasksPaginatedQuery, TasksPaginatedQueryVariables>(TasksPaginatedDocument, options);
      }
export function useTasksPaginatedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TasksPaginatedQuery, TasksPaginatedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TasksPaginatedQuery, TasksPaginatedQueryVariables>(TasksPaginatedDocument, options);
        }
export type TasksPaginatedQueryHookResult = ReturnType<typeof useTasksPaginatedQuery>;
export type TasksPaginatedLazyQueryHookResult = ReturnType<typeof useTasksPaginatedLazyQuery>;
export type TasksPaginatedQueryResult = Apollo.QueryResult<TasksPaginatedQuery, TasksPaginatedQueryVariables>;
export const UsersPaginatedDocument = gql`
    query UsersPaginated($limit: Int!, $search: String, $paginationToken: String) {
  usersPaginated(
    limit: $limit
    search: $search
    paginationToken: $paginationToken
  ) {
    items {
      ...BasicUser
    }
    paginationToken
    limit
  }
}
    ${BasicUserFragmentDoc}`;

/**
 * __useUsersPaginatedQuery__
 *
 * To run a query within a React component, call `useUsersPaginatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersPaginatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersPaginatedQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      search: // value for 'search'
 *      paginationToken: // value for 'paginationToken'
 *   },
 * });
 */
export function useUsersPaginatedQuery(baseOptions: Apollo.QueryHookOptions<UsersPaginatedQuery, UsersPaginatedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersPaginatedQuery, UsersPaginatedQueryVariables>(UsersPaginatedDocument, options);
      }
export function useUsersPaginatedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersPaginatedQuery, UsersPaginatedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersPaginatedQuery, UsersPaginatedQueryVariables>(UsersPaginatedDocument, options);
        }
export type UsersPaginatedQueryHookResult = ReturnType<typeof useUsersPaginatedQuery>;
export type UsersPaginatedLazyQueryHookResult = ReturnType<typeof useUsersPaginatedLazyQuery>;
export type UsersPaginatedQueryResult = Apollo.QueryResult<UsersPaginatedQuery, UsersPaginatedQueryVariables>;
export const CreateUserDocument = gql`
    mutation CreateUser($name: String!, $email: String!, $group: CognitoGroups!, $tenantId: ID) {
  createUser(name: $name, email: $email, group: $group, tenantId: $tenantId) {
    id
    email
    groups
    cognitoUsername
  }
}
    `;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      name: // value for 'name'
 *      email: // value for 'email'
 *      group: // value for 'group'
 *      tenantId: // value for 'tenantId'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const namedOperations = {
  Query: {
    CommentsByTaskId: 'CommentsByTaskId',
    Me: 'Me',
    SearchUsers: 'SearchUsers',
    Task: 'Task',
    TaskDetails: 'TaskDetails',
    TaskTypes: 'TaskTypes',
    TasksPaginated: 'TasksPaginated',
    UsersPaginated: 'UsersPaginated'
  },
  Mutation: {
    AssignOwnersToTask: 'AssignOwnersToTask',
    CreateComment: 'CreateComment',
    CreateSubtask: 'CreateSubtask',
    CreateTask: 'CreateTask',
    MassAssignDueDateToTasks: 'MassAssignDueDateToTasks',
    MassAssignOwnersToTasks: 'MassAssignOwnersToTasks',
    MassAssignStatusToTasks: 'MassAssignStatusToTasks',
    SetGroupsForUser: 'SetGroupsForUser',
    RevisionApproveTask: 'RevisionApproveTask',
    BlockTask: 'BlockTask',
    CancelTask: 'CancelTask',
    UnblockTask: 'UnblockTask',
    RevisionApproveEnhancementTask: 'RevisionApproveEnhancementTask',
    AdditionalRoomShellTask: 'AdditionalRoomShellTask',
    BeginTask: 'BeginTask',
    ReviseTask: 'ReviseTask',
    TriggerRoomShellRoomImagesUpdate: 'TriggerRoomShellRoomImagesUpdate',
    UnassignOwnersFromTask: 'UnassignOwnersFromTask',
    UpdateTask: 'UpdateTask',
    CreateUser: 'CreateUser'
  },
  Fragment: {
    BasicComment: 'BasicComment',
    BasicTask: 'BasicTask',
    BasicUser: 'BasicUser',
    TaskActionReturn: 'TaskActionReturn'
  }
}