import React, { useState } from 'react';
import {
  ArrowDropDown,
} from '@mui/icons-material';
import {
  Button,
  Menu} from '@mui/material';
import { TaskActions, TaskStatuses, TaskDetailsQuery } from '../../../../generated/graphql';
import { BlockMenuItem } from './actions/BlockMenuItem';
import { BlockModal } from '../BlockModal/BlockModal';
import { CancelMenuItem } from './actions/CancelMenuItem';
import { ApproveMenuItem } from './actions/ApproveMenuItem';
import { EnhanceMenuItem } from './actions/EnhanceMenuItem';
import { BeginMenuItem } from './actions/BeginMenuItem';
import { ReviseMenuItem } from './actions/ReviseMenuItem';
import { ReviseModal } from '../ReviseModal/ReviseModal';
import { UpdateInfoMenuItem } from './actions/UpdateInfoMenuItem';
import { AdditionalRoomShellMenuItem } from './actions/AdditionalRoomShellMenuItem';

type Task = TaskDetailsQuery['task'];

interface ActionsMenuProps {
  task: Task;
}

export const ActionsMenu: React.FC<ActionsMenuProps> = ({ task }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [blockModalOpen, setBlockModalOpen] = useState<boolean>(false);
  const [reviseModalOpen, setReviseModalOpen] = useState<boolean>(false);
  const open = !!anchorEl;

  const handleMenuButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => { setAnchorEl(null); };

  return (
  <>
    <Button
      onClick={handleMenuButtonClick}
      variant="contained"
    >
      Actions
      <ArrowDropDown
        fontSize="small"
        style={{ transform: open ? 'rotate(180deg)' : ''}}
      />

    </Button>

    <Menu
      open={open}
      anchorEl={anchorEl}
      onClose={handleMenuClose}
    >
      { shouldShowBlockMenuItem(task) && (
        <BlockMenuItem
          handleMenuClick={() => {
            setBlockModalOpen(true);
            handleMenuClose()
          }}
          task={task}
        />
      )}

      { canYou(task, TaskActions.Approve) && (
        <ApproveMenuItem
          task={task}
          handleMenuClick={handleMenuClose}
        />
      )}

      { canYou(task, TaskActions.Enhance) && (
        <EnhanceMenuItem
          task={task}
          handleMenuClick={handleMenuClose}
        />
      )}

      { canYou(task, TaskActions.AdditionalRoomShell) && (
        <AdditionalRoomShellMenuItem
          task={task}
          handleMenuClick={handleMenuClose}
        />
      )}

      { canYou(task, TaskActions.Cancel) && (
        <CancelMenuItem
          handleMenuClick={handleMenuClose}
          task={task}
        />
      )}

      { canYou(task, TaskActions.Begin) && (
        <BeginMenuItem
          handleMenuClick={handleMenuClose}
          task={task}
        />
      )}

      { canYou(task, TaskActions.Revise) && (
        <ReviseMenuItem
          handleMenuClick={() => {
            setReviseModalOpen(true);
            handleMenuClose()
          }}
          task={task}
        />
      )}

      {!!task.payload.roomShellRequestId && (
        <UpdateInfoMenuItem
          handleMenuClick={() => {
            handleMenuClose();
          }}
          task={task}
        />
      )}
    </Menu>

    { /* Block task modal */ }
    <BlockModal
      open={blockModalOpen}
      handleClose={() => setBlockModalOpen(false)}
      task={task}
    />

    <ReviseModal
      open={reviseModalOpen}
      handleClose={() => setReviseModalOpen(false)}
      task={task}
    />
  </>
  )
}

function shouldShowBlockMenuItem(task: Task) {
  if (task.status === TaskStatuses.Blocked) {
    return canYou(task, TaskActions.Unblock);
  }

  return canYou(task, TaskActions.Block);
}

function canYou(
  task: Task,
  action: TaskActions
): boolean {
  return task.taskActions.includes(action);
}

