import React from 'react';
import { Add } from '@mui/icons-material';
import { ActionMenuItemProps } from './MenuInterface';
import { useAdditionalRoomShellTaskMutation } from '../../../../../generated/graphql';
import { BaseMenuItem, useBaseMenuAction, refetchQueries } from '../BaseMenuItem';

export const AdditionalRoomShellMenuItem: React.FC<ActionMenuItemProps> = ({
  handleMenuClick, task 
}) => {
  const [createAdditionalRoomShellTask, { loading }] = useAdditionalRoomShellTaskMutation();
  const { action } = useBaseMenuAction(
    () => createAdditionalRoomShellTask({
      refetchQueries,
      variables: {
        taskId: task.id.toString()
      }
    }),
    handleMenuClick,
    'Task for additional room shell created',
    'Error creating task for additional room shell'
  )

  return (
    <BaseMenuItem
      menuClick={action}
      loading={loading}
      icon={<Add fontSize="small" />}
      text="Additional Room Shell"
    />
  );
}
